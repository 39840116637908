/* ------------------------------------------------

Page    : Main JS
Version : 1.0
Author  : Surjith S M
URI     : http://themeforest.net/user/surjithctly

Copyright © All rights Reserved
Surjith S M / @surjithctly

-------------------------------------------------- */

(function($) {

    "use strict";



    /* ------------ PAGE LOADING ------------ */

    // hide header first
    $('.fadeInOnLoad').css('opacity', 0);

    // closing loading section on click
    // useful for bored users
    $('#loading').on('click', function() {
        $("#loading").fadeOut();
    });
    /*On Page Load, Fadecout Loading, Start Scroll Animation*/
    $(window).load(function() {
        $("#loading").fadeOut();
        $("#loading .object").delay(700).fadeOut("slow");
        // Show header on load
        $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);

        /*Iniitate Scroll Animation*/
        bodyScrollAnimation()
    })


    /* ------------ ON SCROLL ANIMATION ------------ */


    function bodyScrollAnimation() {
        var scrollAnimate = $('body').data('scroll-animation');
        if (scrollAnimate === true) {
            new WOW({
                mobile: false
            }).init()
        }
    }


    /* ------------ SCROLL SPY ------------ */


    /*Scroll Spy*/
    $('body').scrollspy({
        target: '#main-navbar',
        offset: 100
    });



    /* ================================================
       Scroll Functions
       ================================================ */


    $('nav a[href^="#"]:not([href="#"]), .back_to_top, .explore').on('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 70
        }, 1500);
        event.preventDefault();
    });



    /* ---------- Nav BG ON Scroll---------- */

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 99) {
            $(".navbar-default").addClass("is-scrolling");
        } else {
            $(".navbar-default").removeClass("is-scrolling");
        }
    });


    /* ---------- Back to Top ---------- */

    $(window).scroll(function() {
        if ($(window).scrollTop() > 1000) {
            $('.back_to_top').fadeIn('slow');
        } else {
            $('.back_to_top').fadeOut('slow');
        }
    });


    /* ---------- Contact POPUP ---------- */

    if ($('a[href="#contact-popup"]').length) {

        $('a[href="#contact-popup"]').magnificPopup({
            type: 'inline',
            mainClass: 'mfp-fade',
            midClick: true // mouse middle button click
        });

        $('a[href="#contact-popup"]').on('click', function(e) {

            if ($('#mapBox-popup').length) {

                var $lat = $('#mapBox-popup').data('lat');
                var $lon = $('#mapBox-popup').data('lon');
                var $zoom = $('#mapBox-popup').data('zoom');
                var map = new GMaps({
                    el: '#mapBox-popup',
                    lat: $lat,
                    lng: $lon,
                    scrollwheel: false,
                    scaleControl: true,
                    streetViewControl: false,
                    panControl: true,
                    disableDoubleClickZoom: true,
                    mapTypeControl: false,
                    zoom: $zoom,
                    styles: [
                        { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] },
                        { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] },
                        { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] },
                        { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] },
                        { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] },
                        { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] },
                        { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] },
                        { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
                        { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] },
                        { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] },
                        { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }
                    ]
                })

            }

        });

    }


    /*Feature Notes*/
    $('.feature-note .plus-icon .plus').on('click', function() {
        if ($(this).parents('.feature-note').hasClass('show-cont')) {
            $(this).parents('.feature-note').removeClass('show-cont')
        } else {
            $(this).parents('.feature-note').addClass('show-cont')
        }
    });


    /* ---------- CONTACT FORM FLIPBOX ---------- */

    $('.flip-contact-box').on('click', function() {

        $('.result--contact').html('');

        if (!$('.flip-box-container').hasClass('show-form')) {
            $('.flip-box-container').addClass('show-form')
        }
    });

    $('.js-close-flip').on('click', function() {
        $('.flip-box-container').removeClass('show-form');
    });


    /* ---------- INITIATE EXIT MODAL ---------- */

    var dataexitpopuop = $('body').data('exit-modal');

    if ($('#exit-modal').length && dataexitpopuop === true) {

        var _ouibounce = ouibounce($('#exit-modal')[0], {
            aggressive: true, // use false here to hide message once shown
            timer: 0,
            callback: function() { // if you need to do something, write here
            }
        });
        $('body').on('click', function() {
            $('#exit-modal').hide();
        });
        $('#exit-modal .modal-footer').on('click', function() {
            $('#exit-modal').hide();
        });
        $('#exit-modal .exit-modal').on('click', function(e) {
            e.stopPropagation();
        });

    }



})(jQuery);
