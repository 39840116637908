
// import libs
import cookieBar from './libs/cookieBar';

// import Google map init
import '../plugins/google-map.js';

// import defaults
import './template.js';

// import components
// here
// import './components/countdown.js';
import './components/freshdesk-contact.js';

// init
cookieBar.init({
  // settings
  infoText: 'This website makes use of cookies to enhance browsing experience and provide additional functionality.',
  buttonText: 'Allow cookies',
});

// scripts here
// our styles

var $featureNotes = $('.feature-note');
$featureNotes.on('wow', function() {

  var self = $(this);
  var delay = 500; //in milliseconds

  setTimeout(function() {
    self.addClass('show-cont');
  }, 1000 + ($featureNotes.index(self) * delay));
});


/* ---------- About POPUP ---------- */
if ($('a[href="#about-popup"]').length) {

  $('a[href="#about-popup"]').magnificPopup({
      type: 'inline',
      mainClass: 'mfp-fade',
      midClick: true // mouse middle button click
  });

}


// form email validates
var $submits = $('form').find('[type=submit]');
$submits.on('click', function(e) {

  var valid = false;
  var $form = $(this).closest('form');
  var $email = $form.find('[type=email]');

  if ($email.length) {
    var val = $email.val();

    if (validEmail(val)) {
      valid = true;
    }

    if (!valid) {

      var msg = $form.find('.result').data('success-msg');
      if (typeof msg !== 'undefined') {
        var $p = $form.find('.result p');
        $p.fadeOut('fast').html(msg).fadeIn('fast');
      }

      e.preventDefault();
    }
  }

  // valid

});




function validEmail(input) {
  // var regex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
  var atom = "[-a-z0-9!#$%&'*+/=?^_`{|}~]";
  var domain = '[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])';
  var regex = new RegExp("^" + atom + "+(\\." + atom + "+)*@(" + domain + "?\\.)+" + domain + "$");
  return regex.test(input.toLowerCase());
}
