;(function ($) {

    "use strict";


    // https://github.com/freshdesk/fresh-samples/blob/master/jquery/create_ticket.html
    var contact = {

      defaults: {
        domain: 'DOMAIN',
        apiKey: 'API_KEY',
      },

      form: null,
      config: null,

      init: function(formId, options) {

        contact.formId = formId;
        contact.form = $(formId);

        if (contact.form.length) {

          // create user config
          contact.config = $.extend( {}, contact.defaults, options);

          contact.form.on('click', 'button[type=submit]', function(e) {

            e.preventDefault();

            // create ticket JSON
            var ticket = contact.createTicket();

            // send ticket to helpdesk via AJAX if valid
            if (ticket) {
              contact.sendTicket(ticket);
            }
            else {
              // not valid
              console.log('Your ticket is not valid, check the values');
            }

          });
        }
      },

      createTicket: function() {

        var form = {
          name: $(contact.formId + '-name'),
          email: $(contact.formId + '-email'),
          subject: $(contact.formId + '-subject'),
          message: $(contact.formId + '-message'),
        };

        // console.log(formData);

        if (contact.isValid(form)) {
          var ticketData = '{ "name": "' + form.name.val() + '", "email": "' + form.email.val() + '", "subject": "' + form.subject.val() + '", "description": "' + form.message.val() + '", "priority": 1, "status": 2 }';
          return ticketData;
        }

        return null;

      },

      isValid: function(data) {

        var valid = true;

        function isValidEmail(input) {
          // var regex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
          var atom = "[-a-z0-9!#$%&'*+/=?^_`{|}~]";
          var domain = '[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])';
          var regex = new RegExp("^" + atom + "+(\\." + atom + "+)*@(" + domain + "?\\.)+" + domain + "$");
          return regex.test(input.toLowerCase());
        }

        function isEmpty(input) {
          if (!input.trim()) {
            return true;
          }
          return false;
        }

        // not empty
        for (var name in data) {
          if (isEmpty(data[name].val())) {
            data[name].parent().addClass('has-error');
            valid = false;
          }
          else {
            data[name].parent().removeClass('has-error');
          }
        }

        // email validation
        if (!isValidEmail($(data.email).val())) {
          data.email.parent().addClass('has-error');
          valid = false;
        }
        else {
          data.email.parent().removeClass('has-error');
        }

        return valid;
      },

      sendTicket: function(ticket) {

        $.ajax(
          {
            url: "https://" + contact.config.domain + ".freshdesk.com/api/v2/tickets",
            type: 'POST',
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            headers: {
              "Authorization": "Basic " + btoa(contact.config.apiKey + ":x")
            },
            data: ticket,
            success: function(data, textStatus, jqXHR) {

              // show success to user
              console.log('Your Ticket has been sent');

              // console.log('Success');
              // console.log(jqXHR.status);
              // console.log(JSON.stringify(data, null, "<br/>"));

              // show success message
              var resultEl = $(contact.formId + '-result');
              resultEl.html(resultEl.data('success-msg'));

            },
            error: function(jqXHR, tranStatus) {

              // show error to user
              x_request_id = jqXHR.getResponseHeader('X-Request-Id');
              response_text = jqXHR.responseText;
              console.log(" Error Message : <b style='color: red'>"+ response_text +"</b>.<br/> Your X-Request-Id is : <b>" + x_request_id + "</b>. Please contact support@adogslifegame.com with this id for more information.");

              // console.log('Error');
              // console.log(jqXHR.status);

              // show success message
              var resultEl = $(contact.formId + '-result');
              resultEl.html(resultEl.data('error-msg'));

            }
          }
        );
      },


    };

    contact.init('#contactFooterform',
      {
        domain: 'adogslife',
        apiKey: 'IfSxTuWBgufpkKGsZ3bB',
      }
    );



})(jQuery);